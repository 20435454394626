import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        yes we do
      </Text>
    </Card>
  </Section>
)

export default Commitment
